import React from "react"
import { RichText } from "prismic-reactjs"
import { Row, Col } from "react-grid-system"

import NavBars from "./NavBars"
import "./HireItem.scss"
import { ApiHireItem } from "../types/prismic"
import { useSetRouteOverlay } from "../hooks/RouteOverlay"
import { useWindowSize, isBreakpointMore } from "../hooks/WindowSize"
import LazyImage from "./LazyImage"

type HireItemProps = {
  item: ApiHireItem
  selected: boolean
  onSelect(): void
  onRemove(): void
}

const HireImage: React.FC<{ item: ApiHireItem }> = ({ item }) => {
  const image = item.data.images?.[0]
  if (!image) return null
  return (
    <div className="hire-item__image">
      <LazyImage image={image.image} key={image.image.url} />
    </div>
  )
}

const MobileView: React.FC<HireItemProps> = ({
  item,
  selected,
  onSelect,
  onRemove,
}) => {
  const setRouteOverlay = useSetRouteOverlay()
  const closeAction = () => {
    setRouteOverlay({
      action: "hide",
    })
  }
  return (
    <div className="hire-item__mobile">
      <div className="hire-item__title-row">
        <Row align="center">
          <Col>
            <h4 className="heading--4 hire-item__title">
              {RichText.asText(item.data.hire_item)}
            </h4>
          </Col>
          <Col xs="content">
            <NavBars to="/hire/select" action={closeAction} iconState="close" />
          </Col>
        </Row>
      </div>
      <HireImage item={item} />
      <div className="padding--content">
        <div className="heading--3">{RichText.asText(item.data.price)}</div>
        {selected ? (
          <button className="button hire-item__button" onClick={onRemove}>
            Remove
          </button>
        ) : (
          <button className="button hire-item__button" onClick={onSelect}>
            Select
          </button>
        )}
        {RichText.render(item.data.details)}
      </div>
    </div>
  )
}

const DesktopView: React.FC<HireItemProps> = ({
  item,
  selected,
  onSelect,
  onRemove,
}) => {
  const setRouteOverlay = useSetRouteOverlay()
  const closeAction = () => {
    setRouteOverlay({
      action: "hide",
    })
  }

  return (
    <div className="hire-item__desktop">
      <div className="hire-item__nav hide--md-down">
        <NavBars to="/hire/select" action={closeAction} iconState="close" />
      </div>
      <div className="hire-item background padding--content">
        <Row>
          <Col lg={4}>
            <h1>{RichText.asText(item.data.hire_item)}</h1>
            <div className="heading--3 hire-item__price">
              {RichText.asText(item.data.price)}
            </div>
            {RichText.render(item.data.details)}
            {selected ? (
              <button className="button" onClick={onRemove}>
                Remove
              </button>
            ) : (
              <button className="button" onClick={onSelect}>
                Select
              </button>
            )}
          </Col>
          <Col lg={8}>
            <HireImage item={item} />
          </Col>
        </Row>
      </div>
    </div>
  )
}

const HireItem: React.FC<HireItemProps> = props => {
  const windowSize = useWindowSize()
  const useDesktopView = isBreakpointMore("lg", windowSize)
  return (
    <div className="hire-item__wrap theme-light">
      {useDesktopView ? <DesktopView {...props} /> : <MobileView {...props} />}
    </div>
  )
}

export default HireItem
