import React from "react"
import { Field as FormikField, useFormikContext } from "formik"

type FieldProps = {
  name: string
  label: string
  type?: string
  as?: string
  min?: string
}

type StringObject = {
  [key: string]: string
}
const Field: React.FC<FieldProps> = ({ label, name, ...props }) => {
  const [hasFocus, setHasFocus] = React.useState(false)
  const [touched, setTouched] = React.useState(false)
  const { values, errors } = useFormikContext<StringObject>()
  const floatLabel = !!(values?.[name] || hasFocus)
  const id = `form-field-${name}`
  const classNames = ["form__field", "field--input"]
  if (props.type) classNames.push(`field--input-${props.type}`)
  if (floatLabel) classNames.push("field--float-label")
  return (
    <div className={classNames.join(" ")}>
      <label htmlFor={id} className="form__label">
        {label}
      </label>
      <FormikField
        name={name}
        id={id}
        onFocus={() => {
          setHasFocus(true)
          setTouched(true)
        }}
        onBlur={() => setHasFocus(false)}
        {...props}
      />
      {touched && errors?.[name] && (
        <div className="form__field-errors text-color--warning">
          {errors[name]}
        </div>
      )}
    </div>
  )
}

export default Field
