import React from "react"
import { motion } from "framer-motion"

import { TransitionProps } from "./ITransition"
import { useSetRouteNavState, NavActionStateTypes } from "../hooks/NavState"
import { useWindowSize, isBreakpointMore } from "../hooks/WindowSize"
import "./SecondLevelPage.scss"

const variants = {
  enterStart: {
    translateX: "100%",
  },
  enter: {
    translateX: 0,
  },
  leave: {
    translateX: "100%",
  },
}

interface SecondLevelPageProps extends TransitionProps {
  nextActionState: NavActionStateTypes
}

const SecondLevelPage: React.FC<SecondLevelPageProps> = ({
  children,
  transitionStatus,
  nextActionState,
}) => {
  const windowSize = useWindowSize()
  useSetRouteNavState(
    () => (isBreakpointMore("lg", windowSize) ? "compact" : "closed"),
    transitionStatus,
    nextActionState,
    windowSize
  )
  let animate, initial
  switch (transitionStatus) {
    case "exiting":
      animate = "leave"
      initial = "enter"
      break
    default:
      animate = "enter"
      initial = "enterStart"
      break
  }

  return (
    <motion.div
      className="page--second-level theme-light"
      initial={initial}
      animate={animate}
      variants={variants}
    >
      <div>{children}</div>
    </motion.div>
  )
}

export default SecondLevelPage
