import Prismic from "prismic-javascript"

const apiEndpoint = "https://boss-construction.prismic.io/api/v2"

export default () => {
  return new Promise((resolve, reject) => {
    Prismic.api(apiEndpoint)
      .then(api => {
        resolve(api)
      })
      .catch(reject)
  })
}
