import React from "react"
import { Row, Col } from "react-grid-system"
import { graphql } from "gatsby"
import { RichText } from "prismic-reactjs"
import { Formik, Form, FormikHelpers } from "formik"
import * as Yup from "yup"
import axios from "axios"
import { AnimatePresence, motion } from "framer-motion"
import Prismic from "prismic-javascript"

import SecondLevelPage from "../../components/SecondLevelPage"
import Field from "../../components/forms/Field"
import { TransitionProps } from "../../components/ITransition"
import SEO from "../../components/Seo"
import { NavActionStateTypes } from "../../hooks/NavState"
import {
  PrismicQuery,
  PrismicEdges,
  HireItemEdge,
  ApiHireItem,
} from "../../types/prismic"
import HireGridItem from "../../components/HireGridItem"
import "./select.scss"
import { useWindowSize, isBreakpointMore } from "../../hooks/WindowSize"
import { useSetRouteOverlay } from "../../hooks/RouteOverlay"
import NavBars from "../../components/NavBars"
import prismic from "../../util/prismic"

export const query = graphql`
  query HireQuery {
    prismic {
      allHire_selection_pages {
        edges {
          node {
            title
            getform_id
          }
        }
      }
    }
  }
`

type PageEdge = {
  title: string
  getform_id: string
}

type AllPages = {
  allHire_selection_pages: PrismicEdges<PageEdge>
}

interface FormValues {
  firstName: string
  lastName: string
  email: string
  phone: string
  comments: string
  dateFrom: string
  dateTo: string
}

// Handles the form submission
const onSubmit = (
  id: string,
  selectedItems: string[],
  setSelectedItems: (s: string[]) => void
) => async (
  values: FormValues,
  { setSubmitting, resetForm, setStatus }: FormikHelpers<FormValues>
) => {
  try {
    setStatus("")
    await axios.post(`https://getform.io/f/${id}`, {
      ...values,
      items: selectedItems.join(", "),
    })
    resetForm()
    setStatus("success")
  } catch (e) {
    setStatus("fail")
  } finally {
    setSubmitting(false)
    setSelectedItems([])
  }
}

const today = new Date()
today.setHours(0, 0, 0, 0)
const getMinDateString = (d: Date) => {
  const dd = d.getDate()
  const mm = d.getMonth() + 1 //January is 0!
  const yyyy = d.getFullYear()
  let day = dd.toString()
  let month = mm.toString()
  if (dd < 10) {
    day = "0" + dd
  }
  if (mm < 10) {
    month = "0" + mm
  }

  return yyyy + "-" + month + "-" + day
}
const minDateFormat = getMinDateString(today)

const FormSchema = Yup.object().shape({
  firstName: Yup.string().required("Required"),
  lastName: Yup.string().required("Required"),
  email: Yup.string()
    .email("Invalid email")
    .required("Required"),
  comments: Yup.string().required("Required"),
  dateFrom: Yup.date()
    .min(today, "You cannot choose a date in the past")
    .required("Required"),
  dateTo: Yup.date()
    .min(today, "You cannot choose a date in the past")
    .required("Required"),
})

const removeSelectedItem = (t: string, selectedItems: string[]) => {
  // get the index
  const idx = selectedItems.indexOf(t)
  if (idx === -1) return selectedItems
  return [
    // every element up to the index exclusive
    ...selectedItems.slice(0, idx),
    // every element after the index
    ...selectedItems.slice(idx + 1),
  ]
}

type HireFormProps = {
  selectedItems: string[]
  setSelectedItems(s: string[]): void
  data: PageEdge
}

const HireForm: React.FC<HireFormProps> = ({
  selectedItems,
  setSelectedItems,
  data,
}) => {
  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    comments: "",
    dateFrom: "",
    dateTo: "",
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit(data.getform_id, selectedItems, setSelectedItems)}
      validationSchema={FormSchema}
    >
      {({ isSubmitting, status }) => (
        <Form>
          <Row>
            <Col md={6}>
              <Field name="firstName" label="First Name" />
            </Col>
            <Col md={6}>
              <Field name="lastName" label="Last Name" />
            </Col>
            <Col xs={12}>
              <Field type="email" name="email" label="Email" />
              <Field name="phone" label="Phone" />
              <label className="form__label">Date Required:</label>
            </Col>
            <Col xs={12} md={6}>
              <Field
                name="dateFrom"
                label="From"
                type="date"
                min={minDateFormat}
              />
            </Col>
            <Col xs={12} md={6}>
              <Field name="dateTo" label="To" type="date" min={minDateFormat} />
            </Col>
            <Col xs={12}>
              <Field name="comments" label="Comments" as="textarea" />
              <ul>
                {selectedItems.map(item => (
                  <li key={item}>{item}</li>
                ))}
              </ul>
              <div className="margin--bottom">
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="button"
                >
                  {isSubmitting ? "Sending..." : "Submit"}
                </button>
              </div>
              {status === "fail" && (
                <p className="text-color--warning">
                  There was an error sending your details.
                </p>
              )}
              {status === "success" && (
                <p>Thanks! We&rsquo;ll be in touch shortly</p>
              )}
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  )
}

const HireSelect: React.FC<PrismicQuery<AllPages> & TransitionProps> = ({
  transitionStatus,
  data: prismicData,
}) => {
  const windowSize = useWindowSize()
  const setRouteOverlay = useSetRouteOverlay()
  const [hireItems, setHireItems] = React.useState<ApiHireItem[]>([])
  const [loadingHireItems, setLoadingHireItems] = React.useState(false)

  // fetch all our hire items
  React.useEffect(() => {
    const fetchHireItems = async () => {
      setLoadingHireItems(true)
      try {
        const api: any = await prismic()
        const results = await api.query(
          Prismic.Predicates.at("document.type", "hire_items"),
          {
            pageSize: 1000,
          }
        )
        setHireItems(results.results)
      } catch (e) {
        console.log("Could not load hire items", e)
      } finally {
        setLoadingHireItems(false)
      }
    }
    fetchHireItems()
  }, [])

  const data = prismicData.prismic.allHire_selection_pages.edges?.[0]?.node
  if (!data) return null

  const [selectedItems, setSelectedItems] = React.useState<string[]>([])

  const nextActionState: NavActionStateTypes = {
    to: "/hire",
    icon: "back",
  }

  const Form = (
    <HireForm
      selectedItems={selectedItems}
      setSelectedItems={setSelectedItems}
      data={data}
    />
  )

  const MobileForm = (
    <div className="padding--content hire-mobile">
      <Row align="center" className="hire-mobile__title-row">
        <Col>
          <h1 className="heading--1 hire-mobile__title">Hire Form</h1>
        </Col>
        <Col xs="content">
          <NavBars
            to="/hire/select"
            action={() => {
              setRouteOverlay({ action: "hide" })
            }}
            iconState="close"
          />
        </Col>
      </Row>
      {Form}
    </div>
  )

  return (
    <div>
      <AnimatePresence>
        {selectedItems.length && (
          <motion.div
            className="theme-dark hire__fab"
            animate={{ opacity: 1 }}
            initial={{ opacity: 0 }}
            exit={{ opacity: 0 }}
          >
            <button
              className="button button--secondary hide--md-up"
              onClick={() => {
                setRouteOverlay({
                  action: "setComponent",
                  component: MobileForm,
                })
              }}
            >
              Hire these items
            </button>
          </motion.div>
        )}
      </AnimatePresence>
      <SecondLevelPage
        transitionStatus={transitionStatus}
        nextActionState={nextActionState}
      >
        <SEO title={RichText.asText(data.title)} />
        <div className="sink-gutter--sm-down">
          <Row>
            <Col md={6} lg={12} xl={6}>
              <h1 className="heading--1 hire__title">Hire</h1>
              {isBreakpointMore("md", windowSize) && Form}
            </Col>
            <Col md={6} lg={12} xl={6}>
              <Row gutterWidth={9} className="hire-select__items">
                {hireItems.map(hireItem => (
                  <Col xs={12} md={6} xl={3} key={hireItem.id}>
                    <HireGridItem
                      item={hireItem}
                      selected={selectedItems}
                      onSelect={t => setSelectedItems([...selectedItems, t])}
                      onRemove={t =>
                        setSelectedItems(removeSelectedItem(t, selectedItems))
                      }
                    />
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        </div>
      </SecondLevelPage>
    </div>
  )
}

export default HireSelect
