import React from "react"
import { RichText } from "prismic-reactjs"
import { Row, Col } from "react-grid-system"

import { ApiHireItem } from "../types/prismic"
import "./HireGridItem.scss"
import { useSetRouteOverlay } from "../hooks/RouteOverlay"
import HireItem from "./HireItem"
import ChevronUp from "../assets/icons/chevron-up-small.svg"
import LazyImage from "./LazyImage"

type HireGridItemProps = {
  item: ApiHireItem
  selected: string[]
  onSelect(title: string): void
  onRemove(title: string): void
}

const HireGridItem: React.FC<HireGridItemProps> = ({
  item,
  selected,
  onSelect: onSelectProp,
  onRemove: onRemoveProp,
}) => {
  const setRouteOverlay = useSetRouteOverlay()
  const image = item.data.images?.[0]
  const hireTitle = RichText.asText(item.data.hire_item)
  const isSelected = selected.includes(hireTitle)

  const onSelect = () => {
    onSelectProp(hireTitle)
    setRouteOverlay({
      action: "hide",
    })
  }

  const onRemove = () => {
    onRemoveProp(hireTitle)
    setRouteOverlay({
      action: "hide",
    })
  }

  const onClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    const Item = (
      <HireItem
        item={item}
        onSelect={onSelect}
        onRemove={onRemove}
        selected={isSelected}
      />
    )
    setRouteOverlay({
      action: "setComponent",
      component: Item,
    })
  }

  const classNames = ["hire-grid-item"]
  if (isSelected) classNames.push("hire-grid-item--selected")

  return (
    <div className={classNames.join(" ")}>
      <a href="#" onClick={onClick}>
        <div className="hire-grid-item__image">
          {image && <LazyImage image={image.image} background />}
        </div>
        {isSelected && (
          <div className="hire-grid-item__selected hide--md-down">Selected</div>
        )}
        <div className="hire-grid-item__cover hide--md-down">
          <div className="hire-grid-item__title text-align--center heading--5">
            {hireTitle}
          </div>
        </div>
      </a>
      <div className="hide--md-up">
        <a
          href="#"
          className="hire-grid-item__info-banner text-align--center"
          onClick={onClick}
        >
          <ChevronUp />
          <div className="heading--4">Info</div>
        </a>
        <div className={isSelected ? "theme-dark--force" : "theme-light"}>
          <div className="hire-grid-item__button-row">
            <Row align="center">
              <Col>
                <span className="heading--4">{hireTitle}</span>
              </Col>
              <Col xs="content">
                {isSelected ? (
                  <button
                    className="button button--secondary"
                    onClick={onRemove}
                  >
                    Remove
                  </button>
                ) : (
                  <button
                    className="button button--secondary"
                    onClick={onSelect}
                  >
                    select
                  </button>
                )}
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HireGridItem
